@import "../../../styles/Colors.scss";

// footer {
//   padding: 0px !important;
//   margin-top: 40px;
.footer {
  margin-top: 30px;
  background-color: transparent;

  padding: 20px 50px;
  // border-top: 1px solid $secondaryThree;

  .upper-footer {
    position: relative;
    padding: 20px 0px;

    .big-store-section {
      // position: relative;
      // top: -40px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;
      padding: 10px 0px;
      width: 240px;
      height: 40px;
      background: $secondaryThree 0% 0% no-repeat padding-box;
      border-radius: 24px;
      opacity: 1;

      .ant-typography {
        font-size: 24px;
        font-weight: 100;
        color: $surface;
        text-transform: uppercase;
      }
    }

    .upper-footer-icons-section {
      padding: 30px 0px;
      display: flex;
      justify-content: center;

      .card-container {
        width: 160px;

        .ant-card {
          border-radius: 0px;

          .ant-card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 5px;
            height: 140px;

            h4 {
              color: #161d25;
              font-size: 14px;
              font-family: "ZemestroStd-Medium" !important;
              color: $footerIconColor;
            }

            span {
              color: $secondaryThree;
              font-size: 11px;
            }
          }
        }
      }
    }

    .service-container {
      background-color: $surface;
      width: 400px !important;
      padding: 0px 20px 20px 20px;
      display: flex;
      justify-content: center;

      .service-container-wrapper {
        .programs-text-container {
          text-align: center;
          margin-bottom: 10px;

          h4 {
            color: $secondaryTwo;
            font-family: "ZemestroStd-Medium";
            font-size: 14px;
          }

          span.ant-typography {
            font-size: 12px;
            color: #8b8b8b;

            span {
              color: $primaryTwo;
            }
          }
        }

        .programs-container {
          display: flex;
          justify-content: center;

          .ant-btn {
            padding: 0px 20px;
            margin: 0px 5px;

            span {
              font-size: 12x;
              font-family: "ZemestroStd-Medium" !important;
              font-weight: 100 !important;
            }
          }

          .gold-prime-btn {
            border-color: #9a670c;

            span {
              color: #9a670c;
            }
          }

          .gold-prime-btn:hover {
            background-color: #ebecee;
          }

          .silver-service-btn {
            border-color: #2a1e5c;

            span {
              color: #2a1e5c;
            }
          }

          .silver-service-btn:hover {
            background-color: #ebecee;
          }
        }
      }
    }
  }

  // h5.ant-typography {
  //   font-size: 14px;
  //   font-weight: 700;
  // }

  .ant-row {
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .newsletter-box {
    padding: 20px;
    //background: #f2f3f7 0% 0% no-repeat padding-box;
    background-color: $surface;

    border-radius: 3px;
    opacity: 1;
    display: flex;
    justify-content: center;

    .ant-col {
      text-align: center;
      display: grid;
      row-gap: 10px;
      place-items: center;
      place-content: center;

      .ant-image {
        padding-bottom: 15px;
      }
    }

    h3 {
      font-size: 16px;
      color: $secondaryTwo;
      font-family: "ZemestroStd-Medium" !important;
    }

    span.ant-typography {
      font-size: 12px;
      color: $secondaryTwo;
    }

    .ant-input-affix-wrapper {
      padding: 0px 5px;
      justify-content: center;
    }

    input {
      width: 240px;
    }

    button {
      // width: 330px;
      // height: 40px;
      // background: #5b5b5b 0% 0% no-repeat padding-box;
      // opacity: 1;
      color: $secondaryThree;

      span {
        font-size: 14px;
        font-family: "ZemestroStd-Medium" !important;
        font-weight: 100 !important;
      }

      padding: 4px;

      &:hover {
        background: none !important;
      }
    }

    .newsletter-msg {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .download-title {
    font-size: 14px;
    color: #020202 !important;
    margin-bottom: 0px !important;
    font-weight: 100 !important;
    font-family: "ZemestroStd-Medium" !important;
  }
  .nav-links-container-wrapper {
    width: 100%;
    padding-top: 20px;
    max-width: 1366px;
    margin: auto;

    .nav-links-container {
      width: 100px;

      h4 {
        font-size: 14px;
        color: #020202 !important;
        margin-bottom: 10px;
        font-family: "ZemestroStd-Medium" !important;
      }

      .flex-links-wrap {
        flex-direction: column;
        line-height: 15px;
        .nav-link {
          padding: 5px 0px;

          a {
            font-size: 12px;
            color: $secondaryTwo;
            text-decoration: none !important;
          }

          // &:hover:before {
          //   content: "-";
          //   color: $primaryTwo;
          //   margin-right: 4px;
          // }
        }
      }
    }
  }

  .footer-icons {
    h4 {
      font-size: 14px;
      color: $secondaryTwo;
      margin-bottom: 10px;
      font-family: "ZemestroStd-Regular" !important;
      font-weight: 100 !important;
    }

    svg {
      color: #5b5b5b;
    }
  }

  .copyright-container {
    text-align: center;
    padding: 20px 0px 10px 0px;

    .copyright-text {
      font-size: 16px;
      font-weight: 500;
      // color: $SanaPrimaryRed_color;
      color: "var(--background-color)";
    }
  }
}

.added-footer {
  background-color: $surface;
  padding: 20px 100px;
  height: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  // .sections {
  //   .section {
  //     padding: 10px 0px;
  //     // span.ant-typography {
  //     //   font-size: 12px;
  //     // }
  //   }
  // }

  h1,
  h2 {
    font-family: "ZemestroStd-Medium" !important;
    color: $black;
    font-size: 16px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    padding-top: 20px;
  }

  p {
    font-size: 12px;
  }
}

// }

// Mobile responsive

.footer-mobile {
  display: none !important;
  padding: 10px 10px 0px 10px;
  //border-top: 1px solid $secondaryThree;
  border-top: none;

  .upper-footer {
    padding: 10px 10px 0px 10px;
    gap: 15px;

    .upper-footer-icons-section {
      padding: 0px;

      display: flex;
      justify-content: center;

      .ant-row {
        row-gap: 5px !important;
        column-gap: 5px !important;
        margin-right: -5px !important;

        .card-container {
          flex: 0 0 33.33333333333333%;
          max-width: calc(33.33% - 5px);
          padding: 0px !important;

          .ant-card {
            margin: 0px !important;
            width: 100%;

            .ant-card-body {
              padding: 0px !important;

              h4 {
                font-size: 12px;
                color: $footerIconColor;
              }

              span {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    .service-container {
      background-color: $surface;
      //width: 400px !important;

      padding: 10px 10px 20px 10px;
      border-radius: 4px;

      justify-content: flex-start;

      .service-container-wrapper {
        .programs-text-container {
          text-align: left;
          margin-bottom: 10px;

          h4 {
            margin: 0px;
          }
        }

        .programs-container {
          justify-content: center;
          gap: 8px;

          .ant-btn {
            padding: 0px 20px;
            margin: 0px !important;
          }
        }
      }
    }
  }

  .ant-row {
    padding: 0px;
  }

  .newsletter-box {
    padding: 20px 10px 0px 10px;
    display: revert;
    border-radius: 4px;

    .ant-col {
      text-align: left;
      display: revert;

      h3 {
        font-size: 14px;
      }

      span {
        font-size: 12px;
      }

      form {
        padding-top: 10px;

        input {
          width: 100%;
          border-color: #ed2590;
          border-radius: 4px;
        }

        button {
          // width: 330px;
          // height: 40px;
          // background: #5b5b5b 0% 0% no-repeat padding-box;
          // opacity: 1;
          background-color: #5b5b5b;
          color: #000000;
          color: #ffffff;
          width: 100%;
          border-radius: 4px;

          span {
            font-size: 16px;
            font-family: "ZemestroStd-Regular" !important;
            font-weight: 100;
          }

          padding: 4px;

          &:hover {
            background: none !important;
          }
        }
      }
    }
  }

  .nav-links-container-wrapper {
    padding: 10px 10px 0px 10px;

    .nav-links-container {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 10px;

      h4 {
        margin: 0px;
      }

      // .nav-links-wrapper {
      .flex-links-wrap {
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .nav-link {
          a {
          }
        }
      }

      // }
    }
  }

  .footer-icons {
    //margin: 20px 0px;
    padding: 10px 10px 0px 10px;

    .ant-col {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px 0px;
    }

    svg {
      color: #5b5b5b;
    }
  }

  .copyright-container {
    text-align: center;
    padding: 0px;

    .copyright-text {
      font-size: 10px;
    }
  }
}

.mobile-added-footer {
  background-color: $background;
  padding: 0px 20px 20px 20px;

  .sections {
    h1,
    h2 {
      margin: 0px;
      margin: 20px 0px 5px 0px;
    }

    br {
      display: none;
    }

    h1 {
      font-size: 14px;
    }

    h2 {
      padding-top: 0px;
      font-size: 14px;
    }

    p {
      font-size: 10px;
      color: #000;

      margin: 0px;
    }
  }
}

// Mz css
.footer-seo-description {
  display: none;
}

body .programs-container a {
  text-decoration: unset;
}

.tech-partner > a {
  display: flex;
  flex-direction: column;
}
.tech-partner > a p {
  background-color: #000;
  display: flex;
  color: #fff;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
}
.tech-partner > a p div {
  padding-left: 5px;
}
.tech-partner > a > div {
  padding-left: 5px;
}

@media (max-width: 1024px) {
  .footer-mobile
    .upper-footer
    .service-container
    .service-container-wrapper
    .programs-container {
    justify-content: flex-start;
  }

  body .footer-container {
    padding-top: 0px !important;
  }

  .footer-mobile .upper-footer .service-container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer-mobile .nav-links-container-wrapper .nav-links-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1024px) and (min-width: 767px) {
  .mobile-added-footer {
    padding-bottom: 100px;
  }
}

@media (max-width: 425px) {
  body
    .footer-mobile
    .upper-footer
    .service-container
    .service-container-wrapper
    .programs-container
    .ant-btn {
    width: 100% !important;
  }

  .footer-mobile
    .upper-footer
    .service-container
    .service-container-wrapper
    .programs-container {
    display: grid;
    justify-content: center;
  }
}
