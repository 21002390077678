.upper-footer {
  text-align: center;
  padding: 20px;
  .subscribe-titles {
    display: grid;
    justify-content: center;
    text-align: left;
  }
  .exclusive {
    margin: 0px;
  }

  .subscribe {
  }

  .discount {

    margin: 5px 0px;
  }

  .ant-form-item {
    display: flex;
    justify-content: center;
  }

  .ant-input-affix-wrapper {
    width: 75%;
    margin: 0 auto;
    height: 40px !important;
    border: 1px solid #0F0F0F !important;
  }
  .ant-input-affix-wrapper:focus {
    border-color: #0F0F0F !important;
    box-shadow: none !important;
  }
  .ant-input {
    border-radius: 4px;
  }

  .ant-btn {
    border: none;
    background: transparent;
    font-size: 20px;
  }
}
